import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './componants/Navbar';
import React, { Suspense } from 'react';

import Hero from './componants/Hero';
const MeetCarleen = React.lazy(() => import('./componants/MeetCarleen'));
const CTA = React.lazy(() => import('./componants/CTA'));
const Reviews = React.lazy(() => import('./componants/Reviews'));
const MeetTheTeam = React.lazy(() => import('./componants/MeetTheTeam'));
const Appraisal = React.lazy(() => import('./componants/Appraisal'));
const SeniorDiscounts = React.lazy(() => import('./componants/SeniorDiscounts'));
// Lazy loading components
const Contact = React.lazy(() => import('./componants/Contact'));
const Listings = React.lazy(() => import('./componants/Listings'));
const Sold = React.lazy(() => import('./componants/Sold'));
const House = React.lazy(() => import('./componants/House'));
const Footer = React.lazy(() => import('./componants/Footer'));

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route 
              path="/" 
              element={
                <>
                  <Hero />
                  <MeetCarleen />
                  <CTA />
                  <MeetTheTeam />
                  <SeniorDiscounts />
                  <Reviews />
                </>
              } 
            />
            <Route path="/contact" element={<Contact />} /> 
            <Route path="/appraisal" element={<Appraisal />} /> 
            <Route path="/listings" element={<Listings />} /> 
            <Route path="/sold" element={<Sold />} /> 
            <Route path="/house/:id" element={<House />} /> 
            {/* <Route path="/blog-small-business-website-nz" element={<BlogSmallBusinessWebsiteNZ />} /> 
            <Route path="/blog-mahidesign" element={<BlogMahiDesign />} /> 
            <Route path="/services" element={
              <>
                <Services />
                <ServicesDream />
                <ServicesLikelihood />
                <ServicesEffortTime />
              </>
              } />  */}
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
